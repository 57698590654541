import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminLayoutComponent } from './shared/layouts/admin-layout/admin-layout.component';
import { WelcomeLayoutComponent } from './shared/layouts/welcome-layout/welcome-layout.component';

const routes: Routes = [
  {
    path: 'welcome',
    component: WelcomeLayoutComponent,
    pathMatch: 'full',
  },
  {
    path: 'refund-hub',
    loadChildren: () =>
      import('./features/refund-hub/refund-hub.module').then(
        (m) => m.RefundHubModule,
      ),
  },
  {
    path: '',
    component: AdminLayoutComponent,
    children: [
      {
        path: 'promotion-program',
        loadChildren: () =>
          import('./features/promotion-program/promotion-program.module').then(
            (m) => m.PromotionProgramModule,
          ),
      },
      {
        path: 'voucher-shop',
        loadChildren: () =>
          import('./features/voucher-shop/voucher-shop.module').then(
            (m) => m.VoucherShopModule,
          ),
      },
      {
        path: 'order',
        loadChildren: () =>
          import('./features/order/order.module').then((m) => m.OrderModule),
      },
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./features/dashboard/dashboard.module').then(
            (m) => m.DashboardModule,
          ),
      },
      {
        path: 'shop-management',
        loadChildren: () =>
          import('./features/shop-management/shop-management.module').then(
            (m) => m.ShopManagementModule,
          ),
      },
      {
        path: 'refund-management',
        loadChildren: () =>
          import('./features/refund-management/refund-management.module').then(
            (m) => m.RefundManagementModule,
          ),
      },
      {
        path: 'product-management',
        loadChildren: () =>
          import(
            './features/product-management/product-management.module'
          ).then((m) => m.ProductManagementModule),
      },
      {
        path: 'banner-management',
        loadChildren: () =>
          import('./features/banner-management/banner-management.module').then(
            (m) => m.BannerManagementModule,
          ),
      },
      {
        path: 'soctrip-chat',
        loadChildren: () =>
          import('./features/soctrip-chat/soctrip-chat.module').then(
            (m) => m.SoctripChatModule,
          ),
      },
      {
        path: '**',
        redirectTo: 'dashboard',
        pathMatch: 'full',
      },
    ],
  },

  // {
  //   path: 'refund-hub/refund-info',
  //   loadChildren: () =>
  //     import('./features/refund-hub/refund-hub.module').then(
  //       (m) => m.RefundHubModule,
  //     ),
  // },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
